import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import LoanPrograms from "../components/Repeating/LoanProgramsGridRow";
// import HomeWorth from "../components/Repeating/HomeWorth";
import ValueProps from "../components/Repeating/ValueProps";
import FAQs from "../components/Repeating/FAQs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

import benefit1 from "../images/3.0 Services/Purchase Loans/Leniency for Poor Credit.svg";
import benefit2 from "../images/3.0 Services/Purchase Loans/Flexible Terms.svg";
import benefit3 from "../images/3.0 Services/Purchase Loans/Negotiable Down Payment.svg";
import benefit4 from "../images/3.0 Services/Purchase Loans/Lower Closing Costs.svg";

const Page = ({ data }) => {
  const faqs = [
    {
      question:
        "How does a purchase loan work when the seller owns the home free and clear?",
      answer: (
        <>
          <p>
            First, you provide the seller with a down payment, along with a
            financing instrument (documentation) of the loan. You negotiate the
            monthly payment and interest rate directly with the seller. And
            finally, to protect both parties, the documentation is recorded in
            public records.
          </p>
        </>
      ),
    },
    {
      question:
        "How does a purchase loan work if I’m assuming the seller’s mortgage?",
      answer: (
        <>
          <p>
            You assume the current principal balance, interest rate, repayment
            period, and all other terms of the seller’s mortgage. This is in
            lieu of obtaining a loan from a traditional lender. The types of
            loans that are assumable include FHA loans, VA loans, and USDA
            loans.
          </p>
          <p>
            The advantage of assuming a mortgage is if the interest rate is
            fixed (meaning it’s locked in), you could benefit if it’s lower than
            current market rates. A disadvantage is if the purchase price is
            well over the mortgage balance, requiring a new loan to both assume
            the mortgage and cover the difference. In that instance, you would
            be tied to the current market interest rates.
          </p>
        </>
      ),
    },
    {
      question: "What is a lease option?",
      answer: (
        <>
          <p>
            When you agree to a lease option with a seller, you rent their home
            for a period of time and have the option to buy it once that period
            ends. Typically, a portion of the monthly rent counts toward the
            down payment. You can choose not to purchase the home at the end of
            the lease, but you do forfeit the extra money you paid each month
            that was earmarked for the purchase.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Orange County Purchase Loans | Nikkael Home Loans"
        description="When you negotiate a home loan directly with a seller, it’s called a purchase loan, sometimes referred to as owner financing. Learn more here!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 pt-10 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Seller Financing. No Lender Required.</h1>
              <p>
                When you negotiate a home loan directly with a seller, it’s
                called a purchase loan, sometimes referred to as owner
                financing. It can be a great alternative for those struggling to
                qualify for a traditional loan.
              </p>
              <ButtonSolid href="/request-rates/" text="Request Rates" />
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/3.0 Services/Purchase Loans/1.0 Purchase Loans.jpg"
                loading="lazy"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-12 md:mb-16">
            <h2>The Advantages of a Purchase Loan</h2>
          </header>

          <div className="grid gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit1} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">Leniency for Poor Credit</h3>
                <p className="mb-0">
                  Although your credit report will likely be reviewed, sellers
                  typically are more flexible with their criteria
                </p>
              </div>
            </div>
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit2} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">Flexible Terms</h3>
                <p className="mb-0">
                  You can choose any number of loan options such as fixed-rate,
                  adjustable, interest-only or balloon
                </p>
              </div>
            </div>
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit3} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">Negotiable Down Payment</h3>
                <p className="mb-0">
                  You work with the seller and in some cases can even make
                  periodic lump-sum payments
                </p>
              </div>
            </div>
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit4} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">Lower Closing Costs</h3>
                <p className="mb-0">
                  You won’t have to pay the fees that institutional lenders
                  charge, which means lower closing costs
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/3.0 Services/eligibility.jpg"
                loading="lazy"
                width={560}
              />
            </div>
            <div>
              <h2>What’s the Benefit for the Seller?</h2>
              <p>
                Sellers are taking a risk with a purchase loan, but there are
                benefits for them as well, making it an easier sell for
                borrowers. It can give a seller extra monthly cash flow, for
                example, and they will typically be able to get the full list
                price or more for their home.
              </p>
              <ButtonSolid href="/request-rates/" text="Request Rates" />
            </div>
          </div>
        </div>
      </section>

      {/* <HomeWorth /> */}
      <FAQs heading="Common Questions About Purchase Loans" uniqueFaqs={faqs} />
      <ValueProps />
      <Testimonials />
      <LoanPrograms
        heading="You Might Also Be Interested In"
        hidePrograms={[1,5]}
      />
      <CallToAction
        heading="Considering a Purchase Loan?"
        text="We’re a top purchase loan broker in Orange County, and we’re armed with answers to all your questions. Contact us today!"
      />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-purchase-loans.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-purchase-loans.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
